<template>
  <div>
    <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
    <CCard style="max-width:1050px">
      <CCardHeader>
        <CRow>
          <CCol sm="6">
            Tabela de Preços {{ this.$route.params.id || 1 }}
          </CCol>
          <CCol sm="6" style="text-align:right;">
            <span v-html="carregando"></span>
            <a
                  class="btn btn-link" 
                  href="javascript:;"
                  @click="showModalSimples()" >
                  <CIcon name="cil-cloud-upload" />&nbsp;<small>Importar</small>
              </a>
                <download-excel
                  class="btn btn-link" 
                  :data   = "itemExcel"
                  name = "tabeladeprecos.xls"
                   >
                  <CIcon name="cil-cloud-download" />&nbsp;<small>Exportar</small>
              </download-excel>
               
          </CCol>
        </CRow>
        <b-modal
          id="modal-bemvindo"
          ref="modal-bemvindo"
          :title="'Instalação (Cód.: ' + this.$session.get('grupo') + ')'" 
          no-close-on-esc
          hide-footer 
          no-close-on-backdrop 
          v-model="bemVindo" 
          size="lg"
        >
        <b-img src="https://www.prolucroconsultoria.com.br/wp-content/uploads/2020/05/gerapreco.jpg" fluid alt="Gera Preço ProLucro"></b-img>
        <b-progress :value="progresso" max="100" show-progress animated></b-progress>
        <h4 style="text-align:center;margin-top:10px;">Bem-vindo ao Gera Preço ProLucro!</h4>
        <p style="text-align:center;"><strong>Uma maneira exclusiva, simples e rápida de calcular custos e preços dos seus produtos.</strong></p>
        <p style="text-align:center;">Como este é o seu primeiro acesso, precisamos instalar alguns dados em seu perfil.<br>Selectione o regime tributário da sua empresa abaixo:</p>
        <b-form-select size="lg" style="font-weight:bold; height:42px !important; font-size:16px !important;" v-model="encargo" :options="encSelect"></b-form-select>
        <p style="text-align:center;">Agora, clique no botão abaixo e <strong>aguarde</strong>. O processo leva menos de um minuto.</p>
        <b-button style="padding: 10px; height:50px" :active="iniciarBtn" size="lg" block variant="success" @click="iniciar();">INICIAR</b-button>
        </b-modal>
      </CCardHeader>
      <CCardBody>
        
        <!-- User Interface controls -->
        <b-row>
          <b-col lg="12" class="my-1">
            <b-form-group
              label="Busca"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Digite para procurar..." 
                  v-on:keyup="searchItems"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />
        <div style="color:darkred; text-align:right;">MC Mín: {{ mcminima }} %</div>
        <!-- Main table element -->
        <b-row>
        <b-col md="12">
        
      <CCard v-for="item in items" :key="item.index">
        <CCardHeader style="background-color:lightgrey;">
          <strong>{{ (item.index + 1) }}: {{ item.prd_code }} - {{ item.prd_name }} </strong>
          <br><small><em>{{ item.prd_linha_name }}</em></small>
          <div class="card-header-actions">
            <a 
              class="btn btn-warning card-header-action" 
              rel="noreferrer noopener"
              style="background-color:white;" 
              @click="collapse = !collapse"
            >
              <small class="text-muted">Simular...</small>
            </a>
          </div>
        </CCardHeader>
        <CCardBody style="padding-top:0px;">
          <b-row>
          <b-col md="12">
          <table id="tabelinha" class="table table-sm" style="width:100%; margin-top:10px; border:none;">
            <tbody>
            <tr>
              <td rowspan="2" class="amarelo" style="width:50%;border:none;"><h4 style="text-align:center; margin-top:15px;"><div align="right" class="amarelo" style="padding:3px;">{{ $session.get('regras')[0].grp_moeda }} {{ item.prd_precovenda | grana }}</div></h4></td>
              <td style="width:20%;border:none;"><strong>MC %</strong></td>
              <td style="width:30%;border:none;">
                <div v-if="item.mc_total_perc < mcminima" align="center" class="vermelho" style="padding:3px;">{{ (String(item.mc_total_perc).indexOf('NaN') >= 0 ? '...' : item.mc_total_perc) | grana }} %</div>
                <div v-else align="center" class="amarelo" style="padding:3px;">{{ (String(item.mc_total_perc).indexOf('NaN') >= 0 ? '...' : item.mc_total_perc) | grana }} %</div>
              </td>
            </tr>
            <tr>
              <td><strong>MC R$</strong></td>
              <td>
                <div align="right" class="amarelo" style="padding:3px;">{{ $session.get('regras')[0].grp_moeda }} {{ (String(item.mc_total).indexOf('NaN') >= 0 ? '...' : item.mc_total) | grana }}</div>
              </td>
            </tr>
            </tbody>
          </table>
          </b-col>
          </b-row>
          <CCollapse :show="collapse">
            <CCard body-wrapper>
              <CCardText>
                <table id="tabelinha" class="table table-sm" style="width:100%; border:none;">
                  <tbody>
                  <tr>
                    <td style="width:50%;border:none;">
                      <small><strong>Mudar preço</strong></small><br>
                      <vue-autonumeric :options="percentual" style="text-align:center;" class="verde form-control form-control-sm centro" @blur.native="calcular(item, $event, 0)" :value.sync="item.desconto"></vue-autonumeric>
                    </td>
                    <td style="width:50%;border:none;">
                      <small><strong>Preço de venda 2</strong></small><br>
                      <div align="right" class="amarelo" style="padding:3px;">{{ $session.get('regras')[0].grp_moeda }} {{ (String(item.preco_venda).indexOf('NaN') >= 0 ? '...' : item.preco_venda) | grana }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small><strong>Com. Adic.</strong></small><br>
                      <vue-autonumeric :options="percentual" style="text-align:center;" class="verde form-control form-control-sm centro" @blur.native="calcular(item, $event, 1)" :value.sync="item.comissao"></vue-autonumeric>
                    </td>
                    <td>
                      <small><strong>MC %</strong></small><br>
                      <div v-if="item.mc_perc < mcminima">
                        <div align="center" class="vermelho" style="padding:3px;">{{ (String(item.mc_perc).indexOf('NaN') >= 0 ? '...' : item.mc_perc) | grana }} %</div>
                      </div>
                      <div v-else>
                        <div align="center" class="amarelo" style="padding:3px;">{{ (String(item.mc_perc).indexOf('NaN') >= 0 ? '...' : item.mc_perc) | grana }} %</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small><strong>Nova MC</strong></small><br>
                      <div align="right" class="amarelo" style="padding:3px;">{{ $session.get('regras')[0].grp_moeda }} {{ (String(item.mc_real).indexOf('NaN') >= 0 ? '...' : item.mc_real) | grana }}</div>
                    </td>
                    <td>
                      <small><strong>Variação MC %</strong></small><br>
                      <div v-if="item.mc_perda < 0">
                        <div align="center" class="vermelho" style="padding:3px;min-width:110px;">{{ item.mc_perda | grana }} %</div>
                      </div>
                      <div v-else>
                        <div align="center" class="amarelo" style="padding:3px;min-width:100px;">{{ item.mc_perda | grana }} %</div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </CCardText>
            </CCard>
          </CCollapse>
        </CCardBody>
      </CCard>

        </b-col>
        </b-row>
      </CCardBody>
    </CCard>
    <b-modal size="lg" ref="modal-simples" hide-footer title="Múltiplos materiais">
      <div class="row">
      <div class="col-md-12">
      <b-alert variant="info" show dismissible>
        <p>Utilize o modelo de planilha abaixo para subir todos os descontos e comissões de uma vez.<br>
        Você pode utilizar o EXCEL para copiar e colar aqui.<br>
        A ordem segue naturalmente igual à tabela de preços.</p>
      </b-alert>
      </div>
      <div class="col-md-12">
        <b-alert variant="danger" v-if="isMobile()" show>
        <p><strong>Desculpe!</strong> Este recurso no momento é incompatível com dispositivos móveis.</p>
      </b-alert>
      <hot-table ref="dataTables" :data="dataTable" :settings="dataTableSet"></hot-table>
      </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <b-button variant="success" :disabled="adicionou" @click="salvaSimplesData()">Aplicar</b-button>
          &nbsp;&nbsp;<b-button variant="danger" size="sm" @click="removeSimplesData()">Limpar</b-button>
        </div>
        <div class="col-md-4">
          <b-progress :value="vTbl" :max="maxTbl" show-progress animated></b-progress>
        </div>
      </div>
    </b-modal>
    <a href="javascript:;" @click="scrollBusca()" class="btnfloat">
      <CIcon name="cil-find-in-page" class="my-btnfloat" />
    </a>
  </div>
</template>

<script>

import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
import { HotTable } from '@handsontable/vue'

export default {
  name: 'Dashboard',
  data () {
    return {
      collapse: false,
      cardCollapse: true,
      innerCollapse: false,
      adicionou: false,
      vTbl: 0,
      maxTbl: 0,
      bemVindo: false,
      progresso: 0,
      iniciarBtn: true,
      carregando: '<div align="right"><img src="/img/loading.gif" alt="Aguarde..." /></div>',
      fields: [
        { key: 'linha', label: '#', sortable: false, stickyColumn: true, isRowHeader: true, class: 'text-center' },
        { key: 'prd_linha_name', label: 'Linha', sortable: true, sortDirection: 'asc', stickyColumn: true, isRowHeader: true, class: 'text-center' },
        { key: 'prd_code', label: 'Cód.', sortable: true, stickyColumn: true, isRowHeader: true, class: 'text-center' },
        { key: 'prd_name', label: 'Produto', sortable: true, class: 'text-center', isRowHeader: true, stickyColumn: true },
        { key: 'prd_precovenda', label: 'Preço', class: 'text-center', sortable: true, stickyColumn: true, isRowHeader: true, formatter: (num, key, item) => {
          return this.$session.get('regras')[0].grp_moeda + ' ' + String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ''
        } },
        { key: 'mc_total_perc', class: 'text-center', label: 'MC %', sortable: true, formatter: (num, key, item) => {
          return '' + String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '%'
        } },
        { key: 'mc_total', class: 'text-center', label: 'MC ' + this.$session.get('regras')[0].grp_moeda, sortable: true, formatter: (num, key, item) => {
          return this.$session.get('regras')[0].grp_moeda + ' ' + String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ''
        } },
        { key: 'desconto', class: 'text-center', label: 'Mudar preço', sortable: false, formatter: (num, key, item) => {
          return { index: key, item: item, valor: num }
        } },
        { key: 'preco_venda', class: 'text-center', label: 'Preço de Venda 2', sortable: true, formatter: (num, key, item) => {
          return this.$session.get('regras')[0].grp_moeda + ' ' + String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ''
        } },
        { key: 'comissao', class: 'text-center', label: 'Com. Adic.', sortable: false, formatter: (num, key, item) => {
          return { index: key, item: item, valor: num }
        } },
        { key: 'mc_perc', class: 'text-center', label: 'MC %', sortable: true, formatter: (num, key, item) => {
          return '' + String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' %'
        } },
        { key: 'mc_real', class: 'text-center', label: 'Nova MC', sortable: true, formatter: (num, key, item) => {
          return this.$session.get('regras')[0].grp_moeda + ' ' + String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ''
        } },
        { key: 'mc_perda', class: 'text-center', label: 'Variação MC %', sortable: true, formatter: (num, key, item) => {
          return '' + String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' %'
        } },
        { key: 'mc_perda_real', class: 'text-center', label: 'Variação MC ' + this.$session.get('regras')[0].grp_moeda + ' ', sortable: true, formatter: (num, key, item) => {
          return this.$session.get('regras')[0].grp_moeda + ' ' + String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ''
        } }
      ],
      items: [],
      originalItems: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      simulador: '',
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      nomeProduto: '',
      mcminima: 0,
      itemExcel: [],
      encargo: 'Simples',
      encSelect: [
        {value: 'Lucro Real', text: 'Lucro Real'},
        {value: 'Lucro Presumido', text: 'Lucro Presumido'},
        {value: 'Simples', text: 'Simples'},
        {value: 'MEI', text: 'MEI'}
      ],
      filtroProd: [],
      dataTable: [],
      dataTableSet: {
        colHeaders: ['Desconto (%)', 'Comissão (%)'],
        rowHeaders: true,
        width: 500,
        height: 400,
        colWidths: [200, 200],
        minRows: 10,
        columns: [
          {
            type: 'numeric',
            numericFormat: {
              culture: 'pt-BR',
              pattern: '0,0.00'
            }
          },
          {
            type: 'numeric',
            numericFormat: {
              culture: 'pt-BR',
              pattern: '0,0.00'
            }
          }
        ],
        licenseKey: 'non-commercial-and-evaluation'
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    if (this.isMobile()) {
      this.$router.push('/dashboardmobile/' + (this.$route.params.id || 1))
    }
    this.verificaSessao()
  },
  mounted: function () {
    this.totalRows = this.items.length
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        // this.$router.go({ path: '/dashboard/' + to, params: {id: to} })
        // this.$router.push('/dashboard/' + (to || 1))
        this.carregando = '<div align="right"><img src="/img/loading.gif" alt="Aguarde..." /></div>'
        this.verificaSessao()
      }
    }
  },
  filters: {
    grana: function (num) {
      if (typeof num !== 'undefined') {
        return String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }
    },
    somarArray: function (value) {
      var ret = 0
      for (var i = 0; i < value.length; i++) {
        ret = ret + parseFloat(value[i])
      }
      return ret
    }
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    scrollBusca: function () {
      document.getElementById('filterInput').focus()
      window.scrollTo(0, 100)
    },
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('DASHBOARD'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        // Atualiza cotações (moedas)
        this.$http.get(this.URLApp + 'tabelas/updateCotacao')
        // Verifica se é o primeiro acesso
        this.listar('pro_Encargo', 'enc_ID', '(enc_ID > 0)').then(
          (r) => {
            if (r.length > 0) {
              this.bemVindo = false
            } else {
              this.bemVindo = true
            }
            if (this.$session.get('grupo') > 0) {
              this.campo('gp_Grupo', 'grp_data_validade', 'grp_ID = ' + this.$session.get('grupo')).then(
                (g) => {
                  if (g) {
                    // Verifica a validade do acesso
                    var ate = new Date(g)
                    var hoje = new Date()
                    if (ate < hoje) {
                      alert('ACESSO EXPIRADO. O seu acesso ao sistema venceu em ' + ate.getDate() + '/' + (ate.getMonth()+1))
                      this.$http.delete(this.URLApi + 'user/session', {
                        headers: {
                          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
                          'X-Dreamfactory-API-Key': this.ChaveApi
                        }
                      }).then((response) => {
                        this.$session.destroy()
                        this.$router.push('/login')
                      }, (response) => {
                        this.$session.destroy()
                        this.$router.push('/login')
                      })
                      return false
                    } 
                  }
                },
                (g) => {}
              )
            }
            this.recarregar()
          },
          (r) => {
            this.bemVindo = true
          }
        )
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    showModalSimples: function () {
      this.vTbl = 0
      this.dataTableSet.minRows = this.items.length
      this.$refs['modal-simples'].show()
    },
    salvaSimplesData: function () {
      if (this.dataTable.length === 0) {
        alert('Tabela vazia. Nada a ser aplicado...')
        return false
      }
      this.adicionou = true
      this.maxTbl = ( this.dataTable.length - 1 )
      this.vTbl = 0
      var a = []
      var b = []
      var c = 0
      for (var i = 0; i < this.dataTable.length; i++) {
        a = []
        b = []
        if (typeof this.dataTable[i][0] === 'undefined' || this.dataTable[i][0] === '' || this.dataTable[i][0] === null || isNaN(this.dataTable[i][0])) {
          this.items[i].desconto = 0
        } else {
          this.items[i].desconto = parseFloat(this.dataTable[i][0])
        }
        if (typeof this.dataTable[i][1] === 'undefined' || this.dataTable[i][1] === '' || this.dataTable[i][1] === null || isNaN(this.dataTable[i][1])) {
          this.items[i].comissao = 0
        } else {
          this.items[i].comissao = parseFloat(this.dataTable[i][1])
        }
        a = { index: i }
        b = { target: { value: String(this.items[i].desconto) } }
        // Faz o calculo
        this.calcular(a, b, c)
        this.vTbl++
      }
      this.adicionou = false
      this.$refs['modal-simples'].hide()
    },
    removeSimplesData: function () {
      this.dataTable = []
    },
    iniciar: function () {
      this.iniciarBtn = false
      this.progresso = 5
      // 1. Cria o material fixo
      this.inserir('pro_Material', {resource: [ {mat_tipo_ID: 1, mat_name: 'Insumos Eventuais', mat_unc: 'Fixo', mat_value: 1, mat_unidade_ID: 1, mat_active: 1, mat_total: 1} ]}, '', '1').then(
        (a) => {
          this.progresso = 10
          // Cria a unidade padrão
          this.inserir('pro_Material_Unidade',{resource: [ {mun_name: 'Padrão', mun_active: 1} ]}, '', '1')
          // 2. Cria as unidades
          var w = '?filter=' + encodeURIComponent('grp_ID IS NULL')
          var ordby = '&order=' + encodeURI('mun_ID')
          this.$http.get(this.URLApi + 'prolucro/_table/pro_Material_Unidade' + w + ordby, {
            headers: {
              'X-DreamFactory-Session-Token': this.$session.get('jwt'),
              'X-Dreamfactory-API-Key': this.ChaveApi,
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            var r = response.body.resource
            if (!r) { r = response.body }
            var p = []
            for (var i = 0; i < r.length; i++) {
              p.push(this.inserir('pro_Material_Unidade',{resource: [ {mun_name: r[i].mun_name, mun_sigla: r[i].mun_sigla, mun_conv: r[i].mun_conv, mun_active: r[i].mun_active} ]}, '', '1'))
              this.progresso++
            }
            Promise.all(p).then(
              (k) => {
                // 3. Cria os encargos (e seleciona o padrao)
                this.progresso = 50
                w = '?filter=' + encodeURIComponent('grp_ID IS NULL')
                ordby = '&order=' + encodeURI('enc_ID')
                this.$http.get(this.URLApi + 'prolucro/_table/pro_Encargo' + w + ordby, {
                  headers: {
                    'X-DreamFactory-Session-Token': this.$session.get('jwt'),
                    'X-Dreamfactory-API-Key': this.ChaveApi,
                    'Content-Type': 'application/json'
                  }
                }).then((response) => {
                  r = response.body.resource
                  if (!r) { r = response.body }
                  p = []
                  this.progresso = 60
                  var ativo = 0
                  for (i = 0; i < r.length; i++) {
                    if (r[i].enc_name === this.encargo) {
                      ativo = 1
                    } else {
                      ativo = 0
                    }
                    p.push(this.inserir('pro_Encargo',{resource: [ {enc_name: r[i].enc_name, enc_active: ativo} ]}, '', '1'))
                  }
                  Promise.all(p).then(
                    (k) => {
                      this.progresso = 65
                      var kreal = [0, k[0][Object.keys(k[0])[0]], k[1][Object.keys(k[1])[0]], k[2][Object.keys(k[2])[0]], k[3][Object.keys(k[3])[0]]]
                      // Agora, insere os itens padrão
                      w = '?filter=' + encodeURIComponent('grp_ID IS NULL')
                      ordby = '&order=' + encodeURI('eit_ID')
                      this.$http.get(this.URLApi + 'prolucro/_table/pro_Encargo_Item' + w + ordby, {
                        headers: {
                          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
                          'X-Dreamfactory-API-Key': this.ChaveApi,
                          'Content-Type': 'application/json'
                        }
                      }).then((response2) => {
                        this.progresso = 75
                        var r2 = response2.body.resource
                        if (!r2) { r2 = response2.body }
                        var p2 = []
                        for (i = 0; i < r2.length; i++) {
                          p2.push(this.inserir('pro_Encargo_Item',
                            {resource: [{
                              enc_ID: kreal[r2[i].enc_ID], 
                              eit_group: r2[i].eit_group,
                              eit_ordem: r2[i].eit_ordem,
                              eit_name: r2[i].eit_name,
                              eit_valor_hora: r2[i].eit_valor_hora,
                              eit_valor_mes: r2[i].eit_valor_mes,
                              eit_active: r2[i].eit_active
                            }]}, '', '1'))
                        }
                        Promise.all(p2).then(
                          (k2) => {
                            this.progresso = 90
                            this.atualizar('pro_Encargo', {resource: [ {enc_active: 0} ]}, '(enc_ID > 0)' + (this.$session.get('grupo') ? ' AND (grp_ID = ' + this.$session.get('grupo') + ')' : ''), '', '1').then(
                              (r) => {
                                this.atualizar('pro_Encargo', {resource: [ {enc_active: 1} ]}, '(enc_name LIKE ' + this.encargo + ')', '', '1').then(
                                  (r2) => {
                                    this.progresso = 100
                                    this.$notify({
                                      type: 'success',
                                      title: 'Gera Preço configurado com sucesso!',
                                      text: 'Aguarde! Recarregando a tela...'
                                    })
                                    setTimeout(function(){ document.location.reload() }, 3000)
                                  },
                                  (r2) => {
                                    this.$notify({
                                      type: 'warning',
                                      title: 'Sucesso na instalação, mas houve uma falha ao definir encargo padrão',
                                      text: 'Acesse Configurações > Impostos.'
                                    })
                                    setTimeout(function(){ document.location.reload() }, 2000)
                                  }
                                )
                              },
                              (r) => {
                                this.$notify({
                                  type: 'warning',
                                  title: 'Sucesso na instalação, mas houve uma falha ao definir encargo padrão',
                                  text: 'Acesse Configurações > Impostos.'
                                })
                                setTimeout(function(){ document.location.reload() }, 2000)
                              }
                            )
                          },
                          (k2) => {
                            this.$notify({
                              type: 'danger',
                              title: 'Falha ao criar itens de encargo',
                              text: 'Entre em contato com o suporte da aplicação.'
                            })
                          }
                        )
                      }, (response2) => {
                        this.$notify({
                          type: 'danger',
                          title: 'Falha ao criar encargos',
                          text: 'Entre em contato com o suporte da aplicação.'
                        })
                      })
                    },
                    (k) => {
                      this.$notify({
                        type: 'danger',
                        title: 'Falha ao criar encargo padrão',
                        text: 'Entre em contato com o suporte da aplicação.'
                      })
                    }
                  )
                }, (response) => {
                  this.$notify({
                    type: 'danger',
                    title: 'Falha ao criar dado padrão',
                    text: 'Entre em contato com o suporte da aplicação.'
                  })
                })
              },
              (k) => {
                this.$notify({
                  type: 'danger',
                  title: 'Falha ao criar unidades de compras',
                  text: 'Entre em contato com o suporte da aplicação.'
                })
              }
            )
          }, (response) => {
            this.$notify({
              type: 'danger',
              title: 'Falha ao criar padrão de unidades',
              text: 'Entre em contato com o suporte da aplicação.'
            })
          })
        },
        (a) => {
          this.$notify({
            type: 'danger',
            title: 'Falha ao criar insumo eventual',
            text: 'Entre em contato com o suporte da aplicação.'
          })
        }
      )
    },
    recarregar: function () {
      this.items = []
      this.campo('pro_Encargo', 'enc_total_imposto', '(enc_active = 1)').then(
        (en) => {
          if (en === 0) {
            this.$notify({
              type: 'error',
              title: 'Erro ao buscar produtos e preparos (regime tributário indefinido ou zerado)',
              text: 'Confira suas configurações de regime tributário, encargos e insumos cadastrados'
            })
          }
          var usoParams = {
            g: this.$session.get('grupo'),
            en: en,
            dash: (this.$route.params.id || 1),
            tipo: 1,
            param: (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id)
          }
          this.$http.post(this.URLApp + 'tabelas/filtroProdutoGeraPreco', usoParams).then(
            (rr) => {
              var repetido = -1
              var p = []
              var r = rr.body
              for (var i = 0; i < r.length; i++) {
                if (repetido !== r[i].prd_ID) {
                  if (r[i].prd_precovenda !== null) {
                    this.items.push({
                      index: i,
                      prd_linha_name: r[i].prd_linha_name,
                      prd_code: r[i].prd_code,
                      prd_name: r[i].prd_name,
                      prd_precovenda: r[i].prd_precovenda,
                      mc_total_perc: r[i].mc_total_perc,
                      mc_total: r[i].mc_total,
                      desconto: r[i].desconto,
                      comissao: r[i].comissao,
                      preco_venda: r[i].prd_precovenda,
                      preco_sugerido: 0,
                      mc_perc: r[i].mc_total_perc,
                      mc_real: r[i].mc_total,
                      mc_perda: 0,
                      mc_perda_real: 0,
                      prd_ID: r[i].prd_ID
                    })
                  }
                  this.filtroProd.push({
                    prd_custodireto_total: r[i].prd_custodireto_total,
                    prd_outros_total: r[i].prd_outros_total
                  })
                  // p.push(this.getDescontoZero(r[i].prd_ID))
                }
                repetido = r[i].prd_ID
              }
              Promise.all(p).then(
                (kk) => {
                  for (var j = 0; j < kk.length; j++) {
                    this.items[j].desconto = (typeof kk[j][0] === 'undefined' || kk[j][0] === null ? 0 : kk[j][0])
                    this.items[j].comissao = (typeof kk[j][1] === 'undefined' || kk[j][1] === null ? 0 : kk[j][1])
                    if (this.items[j].prd_precovenda > 0) {
                      this.items[j].preco_venda = (this.items[j].prd_precovenda + (this.items[j].prd_precovenda * (this.items[j].desconto / 100)))
                      // this.items[j].preco_venda = (this.items[j].preco_venda + (this.items[j].preco_venda * (this.items[j].comissao / 100)))
                      // Custo direto: ((this.filtroProd[j].prd_custodireto_total / this.items[j].preco_venda) * 100)
                      this.items[j].mc_perc = 100 - ((this.filtroProd[j].prd_custodireto_total / this.items[j].preco_venda) * 100) - (this.filtroProd[j].prd_outros_total)
                      this.items[j].mc_perc = this.items[j].mc_perc - this.items[j].comissao
                      this.items[j].mc_real = this.items[j].preco_venda * (this.items[j].mc_perc / 100)
                      this.items[j].mc_perda = ((this.items[j].mc_real / this.items[j].mc_total) - 1) * 100
                      this.items[j].mc_perda_real = this.items[j].mc_total - this.items[j].mc_real
                    }
                  }
                  this.totalRows = this.items.length
                  this.listar('gp_Metadados', 'id', '(module LIKE precificador)').then(
                    (m) => {
                      for (var i = 0; i < m.length; i++) {
                        if (m[i].string === 'mcminima') {
                          this.mcminima = m[i].description
                        }
                      }
                      for (var i = 0; i < this.items.length; i++) {
                        if (this.mcminima > 0) {
                          this.items[i].preco_sugerido = (this.filtroProd[i].prd_custodireto_total / ((100 - this.filtroProd[i].prd_outros_total - this.mcminima) / 100))
                        }
                        if (this.items[i].prd_precovenda > 0) {
                          this.itemExcel.push({
                            Linha: this.items[i].prd_linha_name,
                            Cod: this.items[i].prd_code,
                            Nome: this.items[i].prd_name,
                            Preco_Venda1: this.items[i].prd_precovenda,
                            MC_Perc: this.items[i].mc_total_perc.toFixed(3),
                            MC_Reais: this.items[i].mc_total.toFixed(3),
                            Mudar_Preco: this.items[i].desconto,
                            Comissao: this.items[i].comissao,
                            Preco_Venda2: this.items[i].preco_venda,
                            Preco_Sugerido: this.items[i].preco_sugerido.toFixed(3),
                            MC_Perc2: this.items[i].mc_perc.toFixed(3),
                            MC_Reais2: this.items[i].mc_real.toFixed(3),
                            MC_Variacao: this.items[i].mc_perda.toFixed(3)
                          })
                        }
                      }
                      this.carregando = ''
                      this.originalItems = this.items
                      this.apenasCalcular()
                    },
                    (m) => {
                      this.carregando = ''
                      this.apenasCalcular()
                    }
                  )
                },
                (kk) => {
                  this.carregando = ''
                }
              )
            },
            (r) => {
              this.carregando = ''
            }
          )
        },
        (en) => {
          this.carregando = ''
          this.$notify({
            type: 'error',
            title: 'Erro ao buscar produtos e preparos',
            text: 'Confira suas configurações de regime tributário, encargos e insumos cadastrados'
          })
        }
      )
    },
    getDescontoZero: function (r) {
      return [0,0]
    },
    getDesconto: function(r) {
      return this.campo('gp_Metadados', 'description', '(string LIKE desconto_' + r + ') AND (module LIKE tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + ')').then(
        (a) => {
          return this.campo('gp_Metadados', 'description', '(string LIKE desconto_' + r + ') AND (module LIKE tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + ')').then(
            (b) => {
              return [parseFloat(a), parseFloat(b)]
            },
            (b) => {
              return [parseFloat(a), parseFloat(0)]
            }
          )
        },
        (a) => {
          return this.campo('gp_Metadados', 'description', '(string LIKE desconto_' + r + ') AND (module LIKE tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + ')').then(
            (b) => {
              return [parseFloat(0), parseFloat(b)]
            },
            (b) => {
              return [parseFloat(0), parseFloat(0)]
            }
          )
        }
      )
    },
    simular: function (item) {
      this.nomeProduto = item.prd_name
    },
    apenasCalcular: function () {
      var mc_perc, comissao, desconto, preco, mc
      for (var i = 0; i < this.items.length; i++) {
        comissao = this.items[i].comissao
        desconto = this.items[i].desconto
        if (isNaN(desconto)) { desconto = 0 }
        if (isNaN(comissao)) { comissao = 0 }
        this.items[i].comissao = comissao
        this.items[i].desconto = desconto
        preco = this.items[i].prd_precovenda
        mc = this.items[i].mc_total
        if (preco === 0 || typeof preco === 'undefined') {
          return false
        }
        mc_perc = this.items[i].mc_total_perc
        this.items[i].preco_venda = (preco + (preco * (desconto / 100)))
        this.items[i].mc_perc = 100 - ((this.filtroProd[i].prd_custodireto_total / this.items[i].preco_venda) * 100) - (this.filtroProd[i].prd_outros_total)
        this.items[i].mc_perc = this.items[i].mc_perc - comissao
        this.items[i].mc_real = this.items[i].preco_venda * (this.items[i].mc_perc / 100)
        this.items[i].mc_perda = ((this.items[i].mc_real / mc) - 1) * 100
        this.items[i].mc_perda_real = this.items[i].mc_total - this.items[i].mc_real
      }
    },
    calcular: function (item, v, n) {
      if (n === 0) {
        var desconto = parseFloat(v.target.value.replace('.','').replace('.','').replace('%','').replace(' ','').replace(',','.'))
        var comissao = this.items[item.index].comissao
      } else {
        var desconto = this.items[item.index].desconto
        var comissao = parseFloat(v.target.value.replace('.','').replace('.','').replace('%','').replace(' ','').replace(',','.'))
      }
      if (isNaN(desconto)) { desconto = 0 }
      if (isNaN(comissao)) { comissao = 0 }
      this.items[item.index].comissao = comissao
      this.items[item.index].desconto = desconto
      var preco = this.items[item.index].prd_precovenda
      var mc = this.items[item.index].mc_total
      if (preco === 0 || typeof preco === 'undefined') {
        return false
      }
      var mc_perc = this.items[item.index].mc_total_perc
      this.items[item.index].preco_venda = (preco + (preco * (desconto / 100)))
      // this.items[item.index].preco_venda = (this.items[item.index].preco_venda + (this.items[item.index].preco_venda * (comissao / 100)))
      this.items[item.index].mc_perc = 100 - ((this.filtroProd[item.index].prd_custodireto_total / this.items[item.index].preco_venda) * 100) - (this.filtroProd[item.index].prd_outros_total)
      this.items[item.index].mc_perc = this.items[item.index].mc_perc - comissao
      this.items[item.index].mc_real = this.items[item.index].preco_venda * (this.items[item.index].mc_perc / 100)
      this.items[item.index].mc_perda = ((this.items[item.index].mc_real / mc) - 1) * 100
      this.items[item.index].mc_perda_real = this.items[item.index].mc_total - this.items[item.index].mc_real
      // this.$refs.tabelinha.refresh()
      // console.log(this.items)
      // this.$root.$emit('bv::refresh::table', 'my-table')
      // if (n !== 0) {
      this.campo('gp_Metadados', 'id', '(string LIKE desconto_' + this.items[item.index].prd_ID + ') AND (module LIKE tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response > 0) {
            // Ja existe. Atualiza.
            this.atualizar('gp_Metadados', {resource: [ {string: 'desconto_' + this.items[item.index].prd_ID, module: 'tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', description: desconto} ]}, 'id = ' + response, '', '1')
          } else {
            // Nao existe. Cria
            this.inserir('gp_Metadados', {resource: [ {string: 'desconto_' + this.items[item.index].prd_ID, module: 'tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', description: desconto, created_by: this.$session.get('user_ID')} ]}, '', '1')
          }
        }, 
        (response) => {
          // Nao existe. Cria.
          this.inserir('gp_Metadados', {resource: [ {string: 'desconto_' + this.items[item.index].prd_ID, module: 'tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', description: desconto, created_by: this.$session.get('user_ID')} ]}, '', '1')
        }
      )
      this.campo('gp_Metadados', 'id', '(string LIKE comissao_' + this.items[item.index].prd_ID + ') AND (module LIKE tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response > 0) {
            // Ja existe. Atualiza.
            this.atualizar('gp_Metadados', {resource: [ {string: 'comissao_' + this.items[item.index].prd_ID, module: 'tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', description: comissao} ]}, 'id = ' + response, '', '1')
          } else {
            // Nao existe. Cria
            this.inserir('gp_Metadados', {resource: [ {string: 'comissao_' + this.items[item.index].prd_ID, module: 'tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', description: comissao, created_by: this.$session.get('user_ID')} ]}, '', '1')
          }
        }, 
        (response) => {
          // Nao existe. Cria.
          this.inserir('gp_Metadados', {resource: [ {string: 'comissao_' + this.items[item.index].prd_ID, module: 'tabela_precos' + (!this.$route.params.id || parseInt(this.$route.params.id) === 1 ? '' : this.$route.params.id) + '', description: comissao, created_by: this.$session.get('user_ID')} ]}, '', '1')
        }
      )
      // }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    searchItems() {
      if (this.filter === '') {
        this.items = this.originalItems
        return
      }
      var searchedItens = []
      var campo1 = ''
      var campo2 = ''
      var campo3 = ''
      for (var i = 0; i < this.originalItems.length; i++) {
        campo1 = String(this.originalItems[i].prd_name).toLowerCase()
        campo2 = String(this.originalItems[i].prd_code).toLowerCase()
        campo3 = String(this.originalItems[i].prd_linha_name).toLowerCase()
        if (campo1.indexOf(this.filter.toLowerCase()) >= 0 || campo2.indexOf(this.filter.toLowerCase()) >= 0 || campo3.indexOf(this.filter.toLowerCase()) >= 0) {
          searchedItens.push(this.originalItems[i])
        }
      }
      this.items = searchedItens
    }
  },
  components: {
    Loading,
    VueAutonumeric,
    HotTable
  }
}
</script>
